.App {
	text-align: center;
}

.App-logo {
	height: 10vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 5vh;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	font-size: calc(10px + 2vmin);
	color: white;
	padding: 4vh;
}

.App-link {
	color: #61dafb;
}

.documentList {
	display: flex;
	flex-direction: column;
}

.documentCard {
	display: flex;
	justify-content: space-between;
	padding: 5px;
	line-height: 25px;
}

.documentName {
	font-weight: bold;
	text-decoration: none;
}

.documentUploader {
	font-weight: lighter;
	font-size: 1.8vh;
	line-height: 1vh;
	text-align: left;
}

.documentLink:hover {
	color: purple;
	text-decoration-color: purple;
}

.input {
	display: none;
}

.addDocument {
	background-color: #ebebeb;
	min-height: 10vh;
	align-items: center;
	display: flex;
	font-size: 1.8vh;
	line-height: 1vh;
	justify-content: space-around;
	font-size: calc(10px + 2vmin);
}

.addDocumentOpen {
	display: flex;
	flex-direction: row;
}
